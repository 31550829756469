import * as React from "react";

import { Nav } from "reactstrap";
import { WidgetOptions } from "./";
import { setOpacityOnHide } from "../../../components/utils";
import { wrapProps } from "../../../i18n";

interface MenuPropsBase {
    items: React.ReactNode;
    className?: string;
    options?: WidgetOptions;
}

type MenuProps = MenuPropsBase;

interface MenuState {}

class MenuViewBase extends React.PureComponent<MenuProps, MenuState> {
    public render(): JSX.Element | null {
        const { items, className, options } = this.props;
        const hideWidget = setOpacityOnHide(options);
        return <Nav className={`plugin plugin-menu ${className} ${hideWidget}`}>{items}</Nav>;
    }
}

export const MenuView = wrapProps<MenuPropsBase>(MenuViewBase);
